exports.fakeServices = [
  {
    description: "Billing Payments",
    label: "Billing Payments",
    path: "https://realms.feature-123.hub-52.dev.assure.dxc.com",
    domain: "Platform",
  },
  {
    description: "Financials for Life and P&C",
    label: "Financials for Life and P&C",
    path: "https://realms.feature-123.hub-52.dev.assure.dxc.com",
    domain: "Platform",
  },
  {
    description: "Enterprise Billing",
    label: "Enterprise Billing",
    path: "https://realms.feature-123.hub-52.dev.assure.dxc.com",
    domain: "Billing",
  },
  {
    description: "Realms Management application",
    label: "Realms Management",
    path: "https://realms.feature-123.hub-52.dev.assure.dxc.com",
    domain: "Billing",
  },
  {
    description: "Management application2",
    label: "Realms Management2",
    path: "https://realms.feature-123.hub-52.dev.assure.dxc.com",
    domain: "Billing",
  },
  {
    description: "Management application3",
    label: "Realms Management3",
    path: "https://realms.feature-123.hub-52.dev.assure.dxc.com",
    domain: "Policy",
  },
  {
    description: "Management application4",
    label: "Realms Management4",
    path: "https://realms.feature-123.hub-52.dev.assure.dxc.com",
    domain: "Billing",
  },
  {
    description: "Management application5",
    label: "Realms Management5",
    path: "https://realms.feature-123.hub-52.dev.assure.dxc.com",
    domain: "Policy",
  },
  {
    description: "Management application6",
    label: "Realms Management6",
    path: "https://realms.feature-123.hub-52.dev.assure.dxc.com",
    domain: "Product",
  },
];
